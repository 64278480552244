import React from 'react'
import Link from 'gatsby-link'
import {
    Nav,
    Navbar,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap'
import NotificationAlert from 'react-notification-alert'
import logo from '../../assets/img/logo.png'
import {
    Button
} from '../../components'
import choix_Du_Consommateur_French from '../../assets/img/Choix_Du_Consommateur_French.png'

const NavLink = ({children, title, to}) => <Link to={to} title={title}>{children}</Link>;

class DesktopMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            company: false,
            services: false,
            products: false,
            documentations: false,
        }
    }

    render() {

        const pathname = this.props.location;

        return (
            <div className="menu-header hide-mobile">
                <NotificationAlert ref="notificationAlert" />
                <Navbar light>
                    <Link title="Accueil" to="/" className="logo">
                        <img alt="Logo Irriglobe" src={logo} />
                    </Link>
                    <Nav>
                        <a href='https://www.ccaward.com/fr/les-laur%C3%A9ats/grand-montreal-quebec/meilleur-systemes-dirrigation/irriglobe/' target="_blank" rel="noopener noreferrer" ><img src={choix_Du_Consommateur_French} alt="Choix du consommateur" style={{width: '75px', marginRight: 20}} /></a>
                        <NavItem>
                                <NavLink title="Expertises" to="/compagnie/expertises" className={pathname==='/compagnie/expertises'?'active':''}>Expertises</NavLink>
                        </NavItem>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                Services
                            </DropdownToggle>
                            <DropdownMenu right>
                                <Link title="Tous nos services" to="/services" className={pathname==='/services'?'active':''}>
                                    <DropdownItem>
                                        Tous nos Services
                                    </DropdownItem>
                                </Link>

                                <Link title="Irrigation résidentielle" to="/services/irrigation-residentielle" className={pathname === '/services/irrigation-residentielle'
?'active':''}>
                                    <DropdownItem>
                                        Résidentiel
                                    </DropdownItem>
                                </Link>
                                <Link title="Irrigation commerciale" to="/services/irrigation-commerciale" className={pathname==='/services/irrigation-commercialle'?'active':''}>
                                    <DropdownItem>
                                        Commercial
                                    </DropdownItem>
                                </Link>
                                <Link title="Irrigation municipale" to="/services/irrigation-municipale" className={pathname ==='/services/irrigation-municipale'?'active':''}>
                                    <DropdownItem>
                                        Municipal
                                    </DropdownItem>
                                </Link>
                                <Link title="Irrigation toîts vert" to="/services/irrigation-toits-vert" className={pathname==='/services/irrigation-toits-vert'?'active':''}>
                                    <DropdownItem>
                                        Toîts vert
                                    </DropdownItem>
                                </Link>
                                <Link title="Irrigation Agriculture urbaine" to="/services/agriculture-urbaine" className={pathname==='/services/agriculture-urbaine'?'active':''}>
                                    <DropdownItem>
                                        Agriculture urbaine
                                    </DropdownItem>
                                </Link>
                                <Link title="Dispositif d'anti-refoulement" to="/services/dispositif-danti-refoulement" className={pathname==='/services/dispositif-danti-refoulement'?'active':''}>
                                    <DropdownItem>
                                        Dispositif d'anti-refoulement
                                    </DropdownItem>
                                </Link>
                                <Link title="Conception de plans" to="/services/conception-de-plans" className={pathname==='/services/conception-de-plans'?'active':''}>
                                    <DropdownItem>
                                        Conception de plans
                                    </DropdownItem>
                                </Link>
                                <Link title="Irrigation goutte à goutte" to="/services/irrigation-goutte-a-goutte" className={pathname==='/services/irrigation-goutte-a-goutte'?'active':''}>
                                    <DropdownItem>
                                        Irrigation goutte à goutte
                                    </DropdownItem>
                                </Link>
                                <Link title="Récupération eau de pluie" to="/services/recuperation-eau-de-pluie" className={pathname==='/services/recuperation-eau-de-pluie'?'active':''}>
                                    <DropdownItem>
                                        Récupération eau de pluie
                                    </DropdownItem>
                                </Link>
                                <Link title="Entretien annuel" to="/services/entretien-annuel" className={pathname==='/services/entretien-annuel'?'active':''}>
                                    <DropdownItem>
                                        Entretien annuel
                                    </DropdownItem>
                                </Link>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                Produits
                            </DropdownToggle>
                            <DropdownMenu right>
                                <Link title="Produits Irriglobe" to="/produits" className={pathname==='/produits'?'active':''}>
                                    <DropdownItem>
                                        À propos de nos produits
                                    </DropdownItem>
                                </Link>
                                <Link title="Produits Irriglobe" to="/produits/irriglobe" className={pathname==='/produits/irriglobe'?'active':''}>
                                    <DropdownItem>
                                        Irriglobe
                                    </DropdownItem>
                                </Link>
                                <Link title="Produits HydroRain" to="/produits/hydrorain" className={pathname==='/produits/hydrorain'?'active':''}>
                                    <DropdownItem>
                                        HydroRain
                                    </DropdownItem>
                                </Link>
                                <Link title="Produits Rachio" to="/produits/rachio" className={pathname==='/produits/rachio'?'active':''}>
                                    <DropdownItem>
                                        Rachio
                                    </DropdownItem>
                                </Link>
                                <Link title="Produits d'Irrigation Toro" to="/produits/toro" className={pathname==='/produits/toro'?'active':''}>
                                    <DropdownItem>
                                        Toro
                                    </DropdownItem>
                                </Link>
                                <Link title="Produits d'Irrigation Irritrol" to="/produits/irritrol" className={pathname==='/produits/irritrol'?'active':''}>
                                    <DropdownItem>
                                        Irritrol
                                    </DropdownItem>
                                </Link>
                                <Link title="Produits d'Irrigation Rainbird" to="/produits/rainbird" className={pathname==='/produits/rainbird'?'active':''}>
                                    <DropdownItem>
                                        Rainbird
                                    </DropdownItem>
                                </Link>
                                <Link title="Produits d'Irrigation Hunter" to="/produits/hunter" className={pathname==='/produits/hunter'?'active':''}>
                                    <DropdownItem>
                                        Hunter
                                    </DropdownItem>
                                </Link>
                                <Link title="Produits d'Irrigation Fertilisation Ezflo" to="/produits/fertilisation" className={pathname==='/produits/ez-flo/fertilisation'?'active':''}>
                                    <DropdownItem>
                                        Fertilisation EzFlo
                                    </DropdownItem>
                                </Link>
                                <Link title="Produits d'Irrigation systeme de recuperation deau de pluie" to="/produits/rewatec" className={pathname==='/produits/rewatec'?'active':''}>
                                    <DropdownItem>
                                        Rewatec
                                    </DropdownItem>
                                </Link>
                                <Link title="Produits d'Irrigation K-Rain" to="/produits/k-rain" className={pathname==='/produits/k-rain'?'active':''}>
                                    <DropdownItem>
                                        K-Rain
                                    </DropdownItem>
                                </Link>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                Documentations
                            </DropdownToggle>
                            <DropdownMenu right>
                                <Link title="Documentations d'Irrigation HydroRain" to="/documentations/hydrorain" className={pathname==='/documentations/hydrorain'?'active':''}>
                                    <DropdownItem>
                                        HydroRain
                                    </DropdownItem>
                                </Link>
                                <Link title="Documentations d'Irrigation Toro" to="/documentations/toro" className={pathname==='/documentations/toro'?'active':''}>
                                    <DropdownItem>
                                        Toro
                                    </DropdownItem>
                                </Link>
                                <Link title="Documentations d'Irrigation Irritrol" to="/documentations/irritrol" className={pathname==='/documentations/irritrol'?'active':''}>
                                    <DropdownItem>
                                        Irritrol
                                    </DropdownItem>
                                </Link>
                                <Link title="Documentations d'Irrigation Rainbird" to="/documentations/rainbird" className={pathname==='/documentations/rainbird'?'active':''}>
                                    <DropdownItem>
                                        Rainbird
                                    </DropdownItem>
                                </Link>
                                <Link title="Documentations d'Irrigation Hunter" to="/documentations/hunter" className={pathname==='/documentations/hunter'?'active':''}>
                                    <DropdownItem>
                                        Hunter
                                    </DropdownItem>
                                </Link>
                                <Link title="Règlements municipaux d'irrigation" to="/documentations/reglements" className={pathname==='/documentations/reglements'?'active':''}>
                                    <DropdownItem>
                                        Règlements municipaux
                                    </DropdownItem>
                                </Link>
                                <Link title="Documentations d'Irrigation Irriglobe" to="/documentations/irriglobe" className={pathname==='/documentations/irriglobe'?'active':''}>
                                    <DropdownItem>
                                        Irriglobe
                                    </DropdownItem>
                                </Link>
                                <Link title="Documentations d'Irrigation Rachio" to="/documentations/rachio" className={pathname==='/documentations/rachio'?'active':''}>
                                    <DropdownItem>
                                        Rachio
                                    </DropdownItem>
                                </Link>
                                <Link title="Documentations d'Irrigation K-Rain" to="/documentations/k-rain" className={pathname==='/documentations/k-rain'?'active':''}>
                                    <DropdownItem>
                                        K-Rain
                                    </DropdownItem>
                                </Link>
                                <Link title="Documentations d'Irrigation Ez-Flow" to="/documentations/ez-flo" className={pathname==='/documentations/ez-flo'?'active':''}>
                                    <DropdownItem>
                                        Ez-Flo
                                    </DropdownItem>
                                </Link>
                                <Link title="Documentations d'Irrigation Rewatec" to="/documentations/rewatec" className={pathname==='/documentations/rewatec'?'active':''}>
                                    <DropdownItem>
                                        Rewatec
                                    </DropdownItem>
                                </Link>
                                <Link title="Comment installer un système d’irrigation" to="/documentations/comment-installer-un-systeme-irrigation" className={pathname==='/documentations/comment-installer-un-systeme-irrigation'?'active':''}>
                                    <DropdownItem>
                                        Comment installer un système d’irrigation
                                    </DropdownItem>
                                </Link>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <NavItem>
                            <NavLink title="Nous joindre" to="/nous-joindre" className={pathname==='/nous-joindre'?'active':''}>
                                <Button color="primary" size="lg" round>
                                    Nous joindre
                                </Button>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Navbar>
            </div>
        )
    }
}

export default DesktopMenu
